@use '_modules' as *;

aside#services {

  background-color: $grey3;
  box-shadow: none !important;
  @include background-blowout;

  > .text {
    display:flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h3 { 
      margin-top:0; 
      color:$secondary; 
      width:100%; 
      max-width:800px; 
    }
  }

  article-list {
    margin:$spacer auto 0 auto; 
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
  }

}