aside#services {
  background-color: #F4F4F4;
  box-shadow: none !important;
}
aside#services:before {
  content: "";
  background-color: inherit;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  transform: translateX(var(--translateX, -50%));
}
aside#services > .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
aside#services > .text h3 {
  margin-top: 0;
  color: var(--secondary, #DF3035);
  width: 100%;
  max-width: 800px;
}
aside#services article-list {
  margin: var(--spacer1) auto 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
}